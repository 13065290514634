<template>
  <div v-loading="loading">
    <!--    上部开始-->
    <div class="statistical-top">
      <el-tabs v-model="activeName" class="el-nav">
        <el-tab-pane label="内容分析" name="first">
          <div class="statistical-center">
            <div class="btn-list vertical-center">
              <el-button v-for="(item, index) in btnList" :key="index" :type="index === activeIndex ? 'primary' : ''" @click="choseBtn(index)">{{ item.name }}</el-button>
              <div class="btn-list-right">
                共 <span>{{ articleTotal }}</span> {{ activeIndex === 0 || activeIndex === 1 || activeIndex === 3 ? '篇' : '个'
                }}{{ activeIndex === 1 ? '文章' : activeIndex === 2 ? '视频' : activeIndex === 3 ? '直播' : '内容' }}
              </div>
            </div>
            <div class="statistical-center-list space-between">
              <div class="statistical-center-item">
                <div class="statistical-center-item-title">
                  {{ activeIndex !== 1 ? '阅读（播放）总数' : '阅读总数' }}
                </div>
                <div class="statistical-center-item-num">
                  {{ Number(total.read_count) }}
                </div>

                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="Number(yesterday_data.read_count) === 0 ? 'noBg' : Number(yesterday_data.read_count) > 0 ? 'blueBg' : 'redBg'"
                    >{{ Number(yesterday_data.read_count) === 0 ? '' : Number(yesterday_data.read_count) > 0 ? '+' : '-' }}{{ yesterday_data.read_count }}</el-tag
                  >
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-jun">
                  <span>{{ activeIndex !== 1 ? '本周日均阅读(播放)量' : '本周日均阅读量' }}：{{ week_data.read_count }}</span>
                </div>
              </div>
              <div class="statistical-center-item">
                <div class="statistical-center-item-title">点赞总数</div>
                <div class="statistical-center-item-num">
                  {{ Number(total.good_count) }}
                </div>

                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="Number(yesterday_data.good_count) === 0 ? 'noBg' : Number(yesterday_data.good_count) > 0 ? 'blueBg' : 'redBg'"
                    >{{ Number(yesterday_data.good_count) === 0 ? '' : Number(yesterday_data.good_count) > 0 ? '+' : '-' }}{{ yesterday_data.good_count }}</el-tag
                  >
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-jun">
                  <span>本周日均点赞量：{{ week_data.good_count }}</span>
                </div>
              </div>
              <!-- <div class="statistical-center-item">
                <div class="statistical-center-item-title">评论总数</div>
                <div class="statistical-center-item-num">
                  {{ Number(total.comment_count) }}
                </div>

                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="Number(yesterday_data.comment_count) === 0 ? 'noBg' : Number(yesterday_data.comment_count) > 0 ? 'blueBg' : 'redBg'"
                    >{{ Number(yesterday_data.comment_count) > 0 ? '+' : '' }}{{ yesterday_data.comment_count }}</el-tag
                  >
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-jun">
                  <span>本周日均评论量：{{ week_data.comment_count }}</span>
                </div>
              </div> -->
              <div class="statistical-center-item">
                <div class="statistical-center-item-title">收藏总数</div>
                <div class="statistical-center-item-num">
                  {{ Number(total.collect_count) }}
                </div>

                <div class="statistical-center-item-foot">
                  昨日：
                  <el-tag :class="Number(yesterday_data.collect_count) === 0 ? 'noBg' : Number(yesterday_data.collect_count) > 0 ? 'blueBg' : 'redBg'"
                    >{{ Number(yesterday_data.collect_count) === 0 ? '' : Number(yesterday_data.collect_count) > 0 ? '+' : '-' }}{{ yesterday_data.collect_count }}</el-tag
                  >
                </div>
                <el-divider></el-divider>
                <div class="statistical-center-item-jun">
                  <span>本周日均收藏量：{{ week_data.collect_count }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="el-nav-right">
        每天
        <span>1</span>
        点左右更新前一日数据
      </div>
    </div>

    <!--上部结束-->

    <!--    下部统计表开始-->
    <div>
      <!--      全部-->
      <All @getTotal="getTotal" v-if="activeIndex === 0"></All>

      <!--      文章-->
      <Article @getTotal="getTotal" v-else-if="activeIndex === 1"></Article>

      <!--      视频-->
      <Video @getTotal="getTotal" v-else-if="activeIndex === 2"></Video>

      <!--      直播-->
      <!-- <Replay v-else-if="activeIndex === 3"></Replay> -->

      <!--      帖子-->
      <!-- <Posts v-else-if="activeIndex === 4"></Posts> -->
      <!-- <router-view></router-view> -->
    </div>

    <!--    下部统计表结束-->
  </div>
</template>

<script>
import all from './all';
import article from './article';
import video from './video';
import replay from './replay';
import posts from './posts';
export default {
  components: {
    All: all,
    Article: article,
    Video: video,
    Replay: replay,
    Posts: posts,
  },
  name: 'Index',
  data() {
    return {
      loading: false,
      activeName: 'first',
      activeIndex: 0,
      readingsNum: 698457,
      likesNum: 15,
      commentsNum: 0,
      cocollectionsNum: 0,
      readingsOldNum: 0,
      likesOldNum: 15,
      commentsOldNum: -2,
      cocollectionsOldNum: 0,
      activeIndex: 0,
      articleTotal: 0,
      yesterday_data: {},
      week_data: {},
      total: {},
      btnList: [
        {
          id: 0,
          name: '全部',
        },
        {
          id: 1,
          name: '文章',
        },
        {
          id: 2,
          name: '视频',
        },
        // {
        //   id: 3,
        //   name: '回播',
        // },
        // {
        //   id: 4,
        //   name: '帖子',
        // },
      ],
      tableData: [
        {
          date: '2016-05-02',
          num: '500',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-04',
          num: '500',
          address: '上海市普陀区金沙江路 1517 弄',
        },
        {
          date: '2016-05-01',
          num: '500',
          address: '上海市普陀区金沙江路 1519 弄',
        },
        {
          date: '2016-05-03',
          num: '500',
          address: '上海市普陀区金沙江路 1516 弄',
        },
      ],
    };
  },
  created() {
    this.activeIndex = 0;
    switch (this.$route.query.type) {
      case 'article':
        this.activeIndex = 1;
        break;
      case 'video':
        this.activeIndex = 2;
        break;
      case 'replay':
        this.activeIndex = 3;
        break;
      case 'posts':
        this.activeIndex = 4;
        break;
      default:
        this.activeIndex = 0;
    }
    this.judgeType();
  },
  mounted() {
    this.numThousand(12);
    // 清空路由参数
    this.$router.push({ query: {} });
  },
  methods: {
    judgeType() {
      if (this.activeIndex === 1) {
        this.getOverView(1);
      } else if (this.activeIndex === 2) {
        this.getOverView(2);
      } else if (this.activeIndex === 0) {
        console.log('zou;');
        this.getOverView(4);
      }
    },
    // 总数量
    getTotal(val) {
      this.articleTotal = val;
    },
    // 获取数据总览
    getOverView(content_type) {
      this.loading = true;
      let url = `${this.$api.Overview}?content_type=${content_type}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.loading = false;
          this.yesterday_data = res.data.data.yesterday_data;
          this.week_data = res.data.data.week_data;
          this.total = res.data.data.total;
        }
      });
    },
    isNullorEmpty(str) {
      return str === '' || str === null || str === undefined || isNaN(str);
    },
    // 数字三位数一分
    numThousand(num, str) {
      if (this.isNullorEmpty(num)) return '--';
      str = str || '';
      const isNegative = !(num >= 0);
      //是否负数
      if (isNegative) num = Math.abs(num);
      // 获取整数和小数部分
      let intNum = Math.trunc(num),
        flotNum = '';
      if (num.toString().split('.').length > 1) {
        flotNum = num.toString().split('.')[1];
      }
      // 整数部分 千位加逗号
      let c = intNum && intNum !== 0 ? intNum.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,') : '0';
      //是否负数
      if (isNegative) c = '-' + c + '.' + flotNum;
      // return str + c;
      console.log(str + c);
    },
    choseBtn(index) {
      this.activeIndex = index;
      this.judgeType();
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/data/content/index.less';
</style>
