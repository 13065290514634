<template>
  <div>
    <div class="statistical-bottom">
      <div class="statistical-bottom-table">
        <div class="statistical-bottom-table-title space-between">
          <div class="statistical-bottom-table-title-left">单篇文章分析</div>
          <div class="statistical-bottom-table-title-right" @click="downtSatisticsEx('list')">
            <img src="~assets/images/index/down.png" alt="" class="down" />
            <img src="~assets/images/index/down-hover.png" alt="" class="down-hover" />
            导出Excel
          </div>
        </div>
        <div class="table">
          <el-table :data="acticleStatistics" :header-cell-style="{ background: '#F6F8F9', color: '#1C1F21', fontWeight: '400', height: '48' }" stripe id="downsatistics">
            <el-table-column prop="title" label="标题" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="created_at" label="发布时间" width="200px"> </el-table-column>
            <el-table-column prop="read_count" sortable label="阅读数" align="center" width="90px"> </el-table-column>
            <el-table-column prop="good_count" sortable label="点赞数" align="center" width="90px"> </el-table-column>
            <el-table-column prop="collect_count" sortable label="收藏数" align="center" width="90px"> </el-table-column>
            <!-- <el-table-column prop="comment_count" sortable label="评论数" align="center" width="90px"> </el-table-column> -->
            <el-table-column prop="download_count" sortable label="资料下载数" align="center" width="150px"> </el-table-column>
            <el-table-column label="操作" align="center" width="90">
              <template slot-scope="scope">
                <el-button type="text" size="small" class="table-edit" @click="goDetail(scope.row, 'article')">详细分析</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-pagination
          v-if="acticleStatistics.length > 0 && statisticsTotal > 10"
          class="page"
          background
          @current-change="StatisticshandleCurrentChange"
          :current-page="statisticsPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="statisticsPageSize"
          layout=" prev, pager, next,total, jumper"
          :total="statisticsTotal"
        >
        </el-pagination>
      </div>
    </div>
    <div class="statistical-bottom">
      <div class="statistical-bottom-table">
        <div class="statistical-bottom-table-title space-between">
          <div class="statistical-bottom-table-title-left">分日报表</div>
          <div class="statistical-bottom-table-title-right vertical-center" @click="downtSatisticsEx('date')">
            <img src="~assets/images/index/down.png" alt="" class="down" />
            <img src="~assets/images/index/down-hover.png" alt="" class="down-hover" />
            导出Excel
          </div>
        </div>
        <div class="table">
          <el-table
            :data="acticleDay"
            :header-cell-style="{ background: '#F6F8F9', fontSize: '14px', fontWeight: '400', color: '#1C1F21' }"
            :cell-style="{ fontSize: '14px', fontWeight: '400', color: '#71777D' }"
            stripe
            style="width: 100%"
          >
            <el-table-column prop="date" label="日期" width="150"> </el-table-column>
            <el-table-column prop="read_count" sortable label="阅读数" align="center"> </el-table-column>
            <el-table-column prop="good_count" sortable label="点赞数" align="center"> </el-table-column>
            <el-table-column prop="collect_count" sortable label="收藏数" align="center"> </el-table-column>
            <!-- <el-table-column prop="comment_count" sortable label="评论数" align="center"> </el-table-column> -->
          </el-table>
        </div>

        <el-pagination
          v-if="acticleDay.length > 0 && dayTotal > 10"
          class="page"
          background
          @current-change="DayhandleCurrentChange"
          :current-page="dayPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-="dayPageSize"
          layout=" prev, pager, next,total, jumper"
          :total="dayTotal"
          id="downday"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import info from './info';
export default {
  components: {
    Info: info,
  },
  data() {
    return {
      value: 0,
      radio: 0,
      noDow: false,
      isShow: true,
      activeName: 'first',
      all: true,
      single: false,
      statisticsPage: 1,
      statisticsPageSize: 10,
      statisticsTotal: 0,

      dayPage: 1,
      dayPageSize: 10,
      dayTotal: 0,
      options: [
        {
          value: 0,
          label: '最近7天',
        },
        {
          value: 1,
          label: '最近14天',
        },
        {
          value: 2,
          label: '最近30天',
        },
        {
          value: 3,
          label: '最近90天',
        },
      ],
      acticleStatistics: [],
      acticleDay: [],
    };
  },
  created() {
    this.getActicleStatistics();
    this.getActicleDay();
  },
  mounted() {
    // this.drawLine();
  },
  methods: {
    // 下载
    downtSatisticsEx(type) {
      let url = `${this.$api.exportList}?content_type=1&export_type=${type}`;

      this.$http.get(url, true).then((res) => {
        console.log(res);

        window.open(res.data.data);
      });
    },
    // 分页
    StatisticshandleCurrentChange(val) {
      this.statisticsPage = val;
      this.getActicleStatistics();
    },
    DayhandleCurrentChange(val) {
      this.dayPage = val;
      this.getActicleDay();
    },
    // 获取文章列表
    getActicleStatistics() {
      let url = `${this.$api.Statistics}?content_type=1&page=${this.statisticsPage}&pageSize=${this.statisticsPageSize}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.acticleStatistics = res.data.data.data;
          this.statisticsTotal = res.data.data.total;
          this.$emit('getTotal', this.statisticsTotal);
        }
      });
    },
    // 获取分日列表
    getActicleDay() {
      let url = `${this.$api.StatisticsData}?content_type=1&page=${this.dayPage}&pageSize=${this.dayPageSize}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.acticleDay = res.data.data.data;
          this.dayTotal = res.data.data.total;
        }
      });
    },
    goDetail(row, type) {
      console.log(row);
      this.$router.push({
        path: `/analysis/content/detaile?title=${row.title}&id=${row.id}`,
        query: {
          type,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/data/content/index.less';
</style>
