<template>
  <div>
    <div class="statistical-bottom">
      <div class="statistical-bottom-table">
        <div class="statistical-bottom-table-title space-between">
          <div class="statistical-bottom-table-title-left">单篇内容分析</div>
          <div class="statistical-bottom-table-title-right vertical-center" @click="downtSatisticsEx('list')">
            <img src="~assets/images/index/down.png" alt="" class="down" />
            <img src="~assets/images/index/down-hover.png" alt="" class="down-hover" />
            导出Excel
          </div>
        </div>
        <div class="table">
          <el-table :data="allTableData" tooltip-effect="dark" style="width: 100%" stripe :header-cell-style="{ background: '#F6F8F9', color: '#1C1F21', fontWeight: '400' }">
            <!-- <el-table :data="allTableData" :header-cell-style="{ background: '#F6F8F9', color: '#1C1F21', fontWeight: '400' }" stripe style="width: 100%" id="downsatistics"> -->
            <el-table-column prop="title" label="标题" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="created_at" sortable label="发布时间" align="center" width="200px"> </el-table-column>
            <!-- <el-table-column prop="read_total" sortable label="总阅读数" align="center" width="90px"> </el-table-column> -->
            <el-table-column prop="read_count" label="阅读（播放）数" sortable align="center" width="200px"> </el-table-column>
            <el-table-column prop="good_count" label="点赞数" sortable align="center" width="90px"> </el-table-column>
            <el-table-column prop="collect_count" sortable label="收藏数" align="center" width="90px"> </el-table-column>
            <!-- <el-table-column prop="comment_count" sortable label="评论数" align="center" width="90px"> </el-table-column> -->
            <el-table-column label="操作" align="center" width="90">
              <template slot-scope="scope">
                <el-button type="text" size="small" class="table-edit" @click="goDetail(scope.row, 'all')">详细分析</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-pagination
          v-if="allTableData.length > 0 && statisticsTotal > 10"
          class="page"
          background
          @current-change="StatisticshandleCurrentChange"
          :current-page="statisticsPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="statisticsPageSize"
          layout=" prev, pager, next,total, jumper"
          :total="statisticsTotal"
        >
        </el-pagination>
      </div>
    </div>
    <div class="statistical-bottom">
      <div class="statistical-bottom-table">
        <div class="statistical-bottom-table-title space-between">
          <div class="statistical-bottom-table-title-left">分日报表</div>
          <div class="statistical-bottom-table-title-right vertical-center" @click="downtSatisticsEx('date')">
            <img src="~assets/images/index/down.png" alt="" class="down" />
            <img src="~assets/images/index/down-hover.png" alt="" class="down-hover" />
            导出Excel
          </div>
        </div>
        <div class="table">
          <el-table
            :data="allDayTableData"
            :header-cell-style="{ background: '#F6F8F9', fontSize: '14px', fontWeight: '400', color: '#1C1F21' }"
            :cell-style="{ fontSize: '14px', fontWeight: '400', color: '#71777D' }"
            stripe
            style="width: 100%"
            id="downday"
          >
            <el-table-column prop="date" label="日期" width="150"> </el-table-column>
            <el-table-column prop="read_count" label="阅读（播放）数" sortable align="center"> </el-table-column>
            <el-table-column prop="good_count" label="点赞数" sortable align="center"> </el-table-column>
            <!-- <el-table-column prop="comment_count" label="评论数" sortable align="center"> </el-table-column> -->
            <el-table-column prop="collect_count" label="收藏数" sortable align="center"> </el-table-column>
          </el-table>
        </div>

        <el-pagination
          v-if="allDayTableData.length > 0 && dayTotal > 10"
          class="page"
          background
          @current-change="DayhandleCurrentChange"
          :current-page="dayPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-="dayPageSize"
          layout=" prev, pager, next,total, jumper"
          :total="dayTotal"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  data() {
    return {
      activeName: 'first',
      value: 0,
      radio: 0,
      noDow: false,
      statisticsPage: 1,
      statisticsPageSize: 10,
      statisticsTotal: 0,

      dayPage: 1,
      dayPageSize: 10,
      dayTotal: 0,
      allTableData: [],
      allDayTableData: [],
    };
  },
  created() {
    this.getActicleStatistics();
    this.getActicleDay();
  },
  mounted() {
    ////
    // this.drawLine();
  },
  methods: {
    // 下载
    downtSatisticsEx(type) {
      let url = `${this.$api.exportList}?content_type=4&export_type=${type}`;
      this.$http.get(url, true).then((res) => {
        console.log(res.data.data);
        window.open(res.data.data);
      });
    },
    // 分页
    StatisticshandleCurrentChange(val) {
      this.statisticsPage = val;
      this.getActicleStatistics();
    },
    DayhandleCurrentChange(val) {
      this.dayPage = val;
      this.getActicleDay();
    },

    // 获取文章列表
    getActicleStatistics() {
      let url = `${this.$api.Statistics}?content_type=4&page=${this.statisticsPage}&pageSize=${this.statisticsPageSize}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.allTableData = res.data.data.data;
          this.statisticsTotal = res.data.data.total;
          this.$emit('getTotal', this.statisticsTotal);
        }
      });
    },
    // 获取分日列表
    getActicleDay() {
      let url = `${this.$api.StatisticsData}?content_type=4&page=${this.dayPage}&pageSize=${this.dayPageSize}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.allDayTableData = res.data.data.data;
          this.dayTotal = res.data.data.total;
        }
      });
    },
    goDetail(row, all) {
      console.log(this.$route);
      let type = '';
      if (row.content_type === 1) {
        type = 'article';
      } else if (row.content_type === 2) {
        type = 'video';
      }
      this.$router.push({
        path: '/analysis/content/detaile',
        query: {
          type,
          all,
          id: row.id,
          title: row.title,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/data/content/index.less';
</style>
