<template>
  <div>
    <div class="statistical-bottom">
      <div class="statistical-bottom-title vertical-center space-between">
        <div class="vertical-center">
          <i class="el-icon-data-line"></i>
          <span class="statistical-bottom-title-text">详细数据</span>
        </div>
        <div class="btn-right vertical-center">
          <el-button :type="all ? 'primary' : ''" :class="all ? 'all' : 'single'" round @click="choseAll">所有直播分析</el-button>
          <el-button :type="!all ? 'primary' : ''" :class="!single ? 'single' : 'all'" round @click="choseSinge">单篇直播分析</el-button>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="echarts align-center" v-if="isShow">
        <el-select :class="noDow ? 'green_bg' : 'grey_bg'" @visible-change="choseBg" @change="choseTime(value)" v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <div id="myChart" :style="{ width: '100%', height: '480px' }"></div>
        <el-radio-group v-model="radio" text-color="#ffffff" @change="choseType($event)">
          <el-radio :label="0">阅读（播放）数</el-radio>
          <el-radio :label="1">点赞总数</el-radio>
          <el-radio :label="2">评论总数</el-radio>
          <el-radio :label="3">收藏总数</el-radio>
        </el-radio-group>
      </div>
      <div class="statistical-bottom-table" v-if="isShow">
        <div class="statistical-bottom-table-title space-between">
          <div class="statistical-bottom-table-title-left">
            分日报表
          </div>
          <div class="statistical-bottom-table-title-right vertical-center">
            <img src="~assets/images/index/down.png" alt="" class="down" />
            <img src="~assets/images/index/down-hover.png" alt="" class="down-hover" />
            导出Excel
          </div>
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            :header-cell-style="{ background: '#F6F8F9', fontSize: '14px', fontWeight: '400', color: '#1C1F21' }"
            :cell-style="{ fontSize: '14px', fontWeight: '400', color: '#71777D' }"
            stripe
            style="width: 100%"
          >
            <el-table-column prop="date" label="日期" width="150"> </el-table-column>
            <el-table-column prop="num" sortable label="阅读（播放）数" align="center"> </el-table-column>
            <el-table-column prop="num" sortable label="点赞数" align="center"> </el-table-column>
            <el-table-column prop="num" sortable label="评论数" align="center"> </el-table-column>
            <el-table-column prop="num" sortable label="收藏数" align="center"> </el-table-column>
          </el-table>
        </div>

        <el-pagination
          class="page"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="1"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout=" prev, pager, next,total, jumper"
          :total="400"
        >
        </el-pagination>
      </div>
      <div class="statistical-bottom-table" v-else>
        <div class="statistical-bottom-table-title space-between">
          <div class="statistical-bottom-table-title-left">
            分日报表
          </div>
          <div class="statistical-bottom-table-title-right vertical-center">
            <img src="~assets/images/index/down.png" alt="" class="down" />
            <img src="~assets/images/index/down-hover.png" alt="" class="down-hover" />
            导出Excel
          </div>
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            :header-cell-style="{ background: '#F6F8F9', fontSize: '14px', fontWeight: '400', color: '#1C1F21' }"
            :cell-style="{ fontSize: '14px', fontWeight: '400', color: '#71777D' }"
            stripe
            style="width: 100%"
          >
            <el-table-column prop="date" label="文章标题" show-overflow-tooltip width="150"> </el-table-column>
            <el-table-column prop="date" label="发布时间"> </el-table-column>
            <el-table-column prop="num" sortable label="阅读（播放）数" align="center" width="150"> </el-table-column>
            <el-table-column prop="num" sortable label="点赞数" align="center"> </el-table-column>
            <el-table-column prop="num" sortable label="评论数" align="center"> </el-table-column>
            <el-table-column prop="num" sortable label="收藏数" align="center"> </el-table-column>
            <el-table-column prop="num" label="完整阅读率" align="center"> </el-table-column>
            <el-table-column label="操作" align="center" width="70">
              <template slot-scope="scope">
                <el-button type="text" size="small" class="table-edit" @click="goDetail('replay')">详细分析</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-pagination
          class="page"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="1"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout=" prev, pager, next,total, jumper"
          :total="400"
        >
        </el-pagination>
      </div>
      <!-- <Info></Info> -->
    </div>
  </div>
</template>

<script>
import info from './info';
export default {
  components: {
    Info: info,
  },
  data() {
    return {
      isShow: true,
      activeName: 'first',
      value: 0,
      radio: 0,
      all: true,
      single: false,
      noDow: false,
      options: [
        {
          value: 0,
          label: '最近7天',
        },
        {
          value: 1,
          label: '最近14天',
        },
        {
          value: 2,
          label: '最近30天',
        },
        {
          value: 3,
          label: '最近90天',
        },
      ],
      tableData: [
        {
          date: '2016-05-02',
          num: '500',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-04',
          num: '500',
          address: '上海市普陀区金沙江路 1517 弄',
        },
        {
          date: '2016-05-01',
          num: '500',
          address: '上海市普陀区金沙江路 1519 弄',
        },
        {
          date: '2016-05-03',
          num: '500',
          address: '上海市普陀区金沙江路 1516 弄',
        },
      ],
    };
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange() {},
    // 选中的下拉框
    choseBg() {
      this.noDow = !this.noDow;
    },
    // 单选按钮
    choseType(value) {
      this.radio = value;
      console.log(this.radio);
    },
    // 下拉框
    choseTime(val) {
      console.log(val, '====================');
    },
    choseAll() {
      this.all = true;
      this.single = false;
      this.isShow = true;
      this.value = 0;
      this.$nextTick(() => {
        this.drawLine();
      });
    },
    choseSinge() {
      this.all = false;
      this.single = true;
      this.isShow = false;
    },
    goDetail(type) {
      console.log(this.$route);
      this.$router.push({
        path: '/analysis/content/detaile',
        query: {
          type,
        },
      });
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'));
      // 绘制图表
      myChart.setOption({
        title: {
          text: '数据趋势',
          textStyle: {
            fontSize: 16,
            color: '#71777D',
            fontWeight: 400,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              type: 'solid',
              color: '#EBEFF2',
              width: 2,
            },
          },
        },
        // legend: {
        //   data: ['积分', '经验值'],
        //   right: '5%',
        // },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisLine: {
            //不显示坐标轴线
            show: false,
          },
          axisTick: {
            //不显示坐标轴刻度线
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        grid: {
          left: '45px',
          right: '20px',
          top: '70px',
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            color: '#81D8D0', //线条颜色
            smooth: true, //线条平滑
            showSymbol: false,
            symbolSize: 11,
            symbol: 'circle',
            itemStyle: {
              borderWidth: 15,
              borderColor: 'rgba(129, 216, 208, 0.24)',
              color: '#81D8D0',
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(129, 216, 208, 0.62)', // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 255, 255, 0.23)', //   0% 处的颜色
                  },
                ],
                // global: true // 缺省为 false
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/data/content/index.less';
</style>
